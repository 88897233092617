import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { Container, Menu } from './styles';

import logo from '~/assets/logos/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  return (
    <>
      <Container
        isHome={
          location.pathname === '/' || location.pathname === '/novo-site/'
        }
      >
        <div
          id="menu"
          className={`${
            active ||
            scrollPosition > 0 ||
            (location.pathname !== '/' && location.pathname !== '/novo-site/')
              ? 'bg-white shadow-menu-desk'
              : 'bg-transparent'
          } container-fluid py-3 py-lg-0 menu-fix d-lg-flex justify-content-center  justify-content-xxl-start align-items-center`}
        >
          <div className="row w-lg-100">
            <div className="col-12 px-0 px-sm-2">
              <div className="container-xxl px-4 px-lg-0 px-xl-3 px-xxl-4">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center text-center">
                    <div>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/`}
                        className="d-flex align-items-center logo"
                      >
                        <img src={logo} alt="logo" className="me-2" />
                        <span
                          className={`${
                            active ||
                            scrollPosition > 0 ||
                            (location.pathname !== '/' &&
                              location.pathname !== '/novo-site/')
                              ? 'text-logo'
                              : 'text-white'
                          } text-start d-block h6 h5-xxl mb-0 fw-bold`}
                        >
                          INSTITUTO <br />
                          <span className="d-block h4 h2-xxl mb-0 fw-bold">
                            BARUERI
                          </span>
                        </span>
                      </NavLink>
                    </div>
                    <Menu
                      active={active}
                      scroll={
                        location.pathname !== '/' &&
                        location.pathname !== '/novo-site/'
                          ? 100
                          : scrollPosition
                      }
                    >
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                      >
                        <span
                          className={`${
                            active ||
                            scrollPosition > 0 ||
                            (location.pathname !== '/' &&
                              location.pathname !== '/novo-site/')
                              ? 'bg-black'
                              : 'bg-white'
                          }`}
                        />
                        <span
                          className={`${
                            active ||
                            scrollPosition > 0 ||
                            (location.pathname !== '/' &&
                              location.pathname !== '/novo-site/')
                              ? 'bg-black'
                              : 'bg-white'
                          }`}
                        />
                        <span
                          className={`${
                            active ||
                            scrollPosition > 0 ||
                            (location.pathname !== '/' &&
                              location.pathname !== '/novo-site/')
                              ? 'bg-black'
                              : 'bg-white'
                          }`}
                        />
                      </button>
                      <div className="menu-group active">
                        <div className="menu d-flex flex-column flex-lg-row justify-content-center">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/`}
                            className="btn-menu my-4 my-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                            exact
                            activeClassName="active"
                          >
                            Início
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais`}
                            className="btn-menu mb-4 mb-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                            exact
                            activeClassName="active"
                          >
                            Maternais
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/noticias`}
                            className="btn-menu mb-4 mb-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                          >
                            Notícias
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/portal-da-transparencia`}
                            className="btn-menu mb-4 mb-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            exact
                          >
                            Portal da Transparência
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/regulamentos`}
                            className="btn-menu mb-4 mb-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            exact
                          >
                            Regulamentos
                          </NavLink>
                          <Link
                            to={`${process.env.PUBLIC_URL}/canal/fornecedor`}
                            className="btn btn-primary supplier border-0 py-2 px-4 px-lg-2 px-xl-3 rounded-pill text-white mb-4 mb-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                          >
                            Canal do Fornecedor
                          </Link>
                          <Link
                            to={`${process.env.PUBLIC_URL}/canal/prestador`}
                            className="btn btn-provider provider border-0 py-2 px-4 px-lg-2 px-xl-3 rounded-pill text-white mb-4 mb-lg-0 mx-4 mx-lg-2 d-flex justify-content-center align-items-center"
                          >
                            Canal do Prestador
                          </Link>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
