/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';

import { Container, Menu } from './styles';

import logo from '~/assets/logos/generic-logo.svg';
import logoInstitute from '~/assets/logos/logo.svg';
import { useNursery } from '~/hooks/Nursery';
import { useResize } from '~/hooks/Resize';

interface IParams {
  slug: string;
}

const Header: React.FC = () => {
  const { nursery } = useNursery();
  const { width } = useResize();
  const params = useParams<IParams>();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  const name = useMemo(() => {
    if (Object.keys(nursery).length > 0) {
      const nameParts = nursery.name.split(' ');
      const firstPart = nameParts.slice(0, 2);
      const lastPart = nameParts.slice(2);

      return `${firstPart.join(' ')}<br>${lastPart.join(' ')}`;
    }
    return '';
  }, [nursery]);

  return (
    <>
      <Container>
        <div
          id="menu"
          className="bg-white shadow-menu-desk container-fluid py-3 py-lg-0 menu-fix d-lg-flex align-items-center"
        >
          <div className="row w-lg-100">
            <div className="col-12">
              <div className="container px-0 px-sm-3">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center text-center">
                    <div>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/maternais/${params.slug}`}
                        className="d-flex align-items-center logo"
                      >
                        <img src={logo} alt="logo" className="me-2" />
                        <span
                          className="text-logo text-start d-block h6 h5-lg mb-0 fw-semibold"
                          dangerouslySetInnerHTML={{ __html: name }}
                        />
                      </NavLink>
                    </div>
                    <Menu active={active} scrool={scrollPosition}>
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                      >
                        <span />
                        <span />
                        <span />
                      </button>
                      <div className="menu-group active">
                        <div className="menu d-flex flex-column flex-lg-row justify-content-center">
                          {console.log(width)}
                          {width <= 991 && (
                            <NavLink
                              to={`${process.env.PUBLIC_URL}/maternais/${params.slug}`}
                              className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                              exact
                              activeClassName="active"
                            >
                              Início
                            </NavLink>
                          )}
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/sobre`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            exact
                            activeClassName="active"
                          >
                            Sobre
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/projetos`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            exact
                            activeClassName="active"
                          >
                            Projetos
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/galerias`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                          >
                            Galerias
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/noticias`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                          >
                            Notícias
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/cardapios`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            exact
                          >
                            Cardápios
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/fale-conosco`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            exact
                          >
                            Fale conosco
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/faca-parte-da-equipe`}
                            className="btn-menu mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            exact
                          >
                            Faça parte da equipe
                          </NavLink>
                          <Link
                            to={`${process.env.PUBLIC_URL}/`}
                            className="intitute py-2 rounded-pill text-white mb-4 mb-lg-0 mx-3 d-flex justify-content-center align-items-center"
                          >
                            <img src={logoInstitute} alt="Instituto Barueri" />{' '}
                            Instituto
                          </Link>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
