import React, { useMemo } from 'react';
import { CiFacebook } from 'react-icons/ci';
import { AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';

import { HiOutlineMail } from 'react-icons/hi';
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiYoutubeLine,
} from 'react-icons/ri';
import { Container } from './styles';

import logo from '~/assets/logos/logo.svg';
import PoweredBy from '../PoweredBy';

const Footer: React.FC = () => {
  const location = useLocation();

  const showFooter = useMemo(() => {
    return (
      (!location.pathname.includes('/fornecedor/') &&
        !location.pathname.includes('/prestador/')) ||
      location.pathname.includes('/canal/')
    );
  }, [location.pathname]);

  return (
    <>
      {showFooter && (
        <Container className="pb-5">
          <div className="container pb-5 pb-lg-0">
            <div className="row">
              <div className="col-lg-4 col-xl-3 adress-side px-4 px-lg-3">
                <div className="d-flex mb-4">
                  <img src={logo} alt="logo" className="logo me-3 me-lg-4" />
                  <span className="text-logo text-start d-block h6 h5-lg mb-0 fw-bold">
                    INSTITUTO <br />
                    <span className="d-block h3 h2-lg mb-0 fw-bold">
                      BARUERI
                    </span>
                  </span>
                </div>
                <p className="mb-0">CNPJ: 18.787.461/0001-11</p>
                <p>
                  Av. Brigadeiro Manoel Rodrigues Jordão, 334- Sala 4 Jardim
                  Silveira - Barueri/SP CEP: 06433-010
                </p>
                <hr />
                <div className="mt-4 mb-5 mb-lg-0">
                  <a
                    href="mailto:institutobarueri@contato.org.br"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center"
                  >
                    <HiOutlineMail size={22} color="#E6883B" className="me-2" />{' '}
                    institutobarueri@contato.org.br
                  </a>
                </div>
              </div>
              <div className="col-lg-8 col-xl-9 services-side px-4 px-lg-3">
                <div className="d-lg-flex justify-content-lg-end">
                  <div className="text-lg-end pe-lg-5 mb-5 mb-lg-0">
                    <h3>Serviços</h3>
                    <Link
                      to={`${process.env.PUBLIC_URL}/canal-do-fornecedor`}
                      className="d-block"
                    >
                      Canal do fornecedor
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/faca-parte-da-equipe`}
                      className="d-block"
                    >
                      Faça parte da equipe
                    </Link>
                  </div>
                  <div className="text-lg-end pe-lg-5 mb-5 mb-lg-0">
                    <h3>Recursos</h3>
                    <Link to="/" className="d-block">
                      Termos de uso
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/regulamentos`}
                      className="d-block"
                    >
                      Regulamentos
                    </Link>
                    <a
                      href="https://portal.barueri.sp.gov.br/diario"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Jornal oficial de Barueri
                    </a>
                    <a
                      href="https://www.educbarueri.sp.gov.br/"
                      className="d-block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Secretaria da educação
                    </a>
                  </div>
                  <div className="text-lg-end mb-5 mb-lg-0">
                    <h3>Instituto</h3>
                    <Link
                      to={`${process.env.PUBLIC_URL}/#sobre-nos`}
                      className="d-block"
                    >
                      Sobre nós
                    </Link>
                    <Link to="/" className="d-block">
                      L.G.P.D
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 social text-lg-end px-4 px-lg-3">
                <h3 className="">Nossas redes</h3>
                <a
                  href="https://www.instagram.com/institutobarueri/"
                  target="_blank"
                  title="Instagram"
                  rel="noreferrer"
                >
                  <RiInstagramLine size={34} color="#000" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100076309233808"
                  className="ms-2"
                  target="_blank"
                  title="Facebook"
                  rel="noreferrer"
                >
                  <RiFacebookCircleLine size={34} color="#000" />
                </a>
                <a
                  href="https://www.youtube.com/@institutobarueri"
                  className="ms-2"
                  target="_blank"
                  title="youtube"
                  rel="noreferrer"
                >
                  <RiYoutubeLine size={34} color="#000" />
                </a>
              </div>
            </div>
          </div>
        </Container>
      )}
      <PoweredBy />
    </>
  );
};

export default Footer;
