import React, { useEffect, useMemo, useState } from 'react';

import { BannerTrasparency, Container } from './styles';
import api from '~/services/api';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';

interface IArchive {
  id: number;
  title: string;
  archive_url: string;
}

interface INursery {
  id: number;
  name: string;
  archives: IArchive[];
}

const TransparencyPortal: React.FC = () => {
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: {
          type: 'TransparencyPortal',
        },
      })
      .then((response) => {
        setNurseries(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const data = useMemo(() => {
    let index = 0;
    let color = '';

    return nurseries.map((nursery) => {
      switch (index) {
        case 1:
          color = '#224B8F';
          break;
        case 2:
          color = '#B76522';
          index = -1;
          break;

        default:
          color = '#00A189';
          break;
      }

      index += 1;

      return {
        ...nursery,
        color,
      };
    });
  }, [nurseries]);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 h1-lg mb-4 mb-lg-5">Portal da Transparência</h1>
            <p className="h6 h4-lg fw-light mb-4 mb-lg-5 pb-lg-5">
              O <b>Portal da Transparência</b> do Instituto Barueri tem a
              finalidade de demonstrar dados e informações detalhados sobre a
              execução orçamentária e financeira, objetivando o gerenciamento,
              operacionalização e execução das ações e serviços de educação nas
              maternais. A Prestação de Contas é um dever do Instituto, e o
              acesso irrestrito às mesmas é um direito do cidadão.
            </p>
          </div>
          {data.length > 0 ? (
            <>
              {data.map((nursery) => (
                <>
                  <div className="col-12 ">
                    <h2 className="h5 h4-lg fw-normal bg-name mb-4 py-3 px-4">
                      {nursery.name}
                    </h2>
                  </div>
                  {nursery.archives.length > 0 ? (
                    <>
                      <div className="col-12 d-flex flex-wrap justify-content-start px-xxl-4 overflow-auto all">
                        {nursery.archives.map((archive) => (
                          <BannerTrasparency
                            color={nursery.color}
                            className="mb-4 mb-lg-5"
                          >
                            <a
                              href={archive.archive_url}
                              className="h-100 d-flex flex-column justify-content-end px-4 py-5 px-xl-5"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <h5 className="h5 fw-bold text-white">
                                {archive.title}
                              </h5>
                            </a>
                          </BannerTrasparency>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="col-12 mb-5">
                      <NoData message="Nenhum relatório cadastrado" />
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <div className="col-12 mb-5">
              <NoData message="Nenhum relatório cadastrado" />
            </div>
          )}
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default TransparencyPortal;
