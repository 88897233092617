import React, { useCallback, useEffect, useState } from 'react';
import SliderSlick from 'react-slick';

import { Link } from 'react-router-dom';
import { MdEmail, MdInterests, MdOutlineComputer } from 'react-icons/md';
import { RiHeart3Fill } from 'react-icons/ri';
import {
  Container,
  Hero,
  Banner,
  Notices,
  Institute,
  Curriculum,
  Values,
  Secretary,
} from './styles';
import logo from '~/assets/logos/logo.svg';
import secretary from '~/assets/defaults/secretary.png';
import banner1 from '~/assets/banners/banner-1.png';
import institute from '~/assets/defaults/nosso-instituto.png';
import structures from '~/assets/banners/structures.png';
import structuresMob from '~/assets/banners/structures-mob.png';
import mission from '~/assets/defaults/mission.svg';
import missionArc from '~/assets/defaults/mission-arc.png';
import vision from '~/assets/defaults/vision.svg';
import values from '~/assets/defaults/values.svg';
import visionValuesArc from '~/assets/defaults/vision-values-arc.png';
import api from '~/services/api';
import Loading from '~/components/Loading';

interface INotice {
  id: number;
  title: string;
  slug: string;
  thumbnail: {
    archive_url: string;
  };
}

interface INoticeResponse {
  data: INotice[];
  from: number;
  to: number;
  total: number;
}

const Home: React.FC = () => {
  const [btnSelected, setBtnSelected] = useState('mission');
  const [notices, setNotices] = useState<INotice[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<INoticeResponse>('notices')
      .then((response) => {
        const data = response.data.data.slice(0, 3).map((notice) => ({
          id: notice.id,
          title: notice.title,
          slug: notice.slug,
          thumbnail: {
            archive_url: notice.thumbnail?.archive_url || '',
          },
        }));
        setNotices(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleBtnMisVisVal = useCallback((selected) => {
    setBtnSelected(selected);
  }, []);

  return (
    <Container>
      <Hero>
        <Banner src={banner1} className="d-flex align-items-end py-5 py-lg-0">
          <div className="container">
            <div className="row">
              <div className="col-12 px-4 px-lg-3">
                <div className="logo">
                  <img src={logo} alt="logo" className="mb-3" />
                  <span className="text-white text-start d-block h2 display-lg-4 mb-0 fw-bold">
                    <span>INSTITUTO</span> <br />
                    <span className=" display-4 display-lg-1 mb-0 fw-bold">
                      BARUERI
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Banner>
      </Hero>
      {notices.length > 0 && (
        <Notices>
          <div className="container">
            <div className="row">
              <div className="col-12 px-4 px-lg-3 mt-lg-5 pt-5">
                <h2 className="mb-4">Notícias</h2>
                <SliderSlick autoplay={false} dots arrows autoplaySpeed={3000}>
                  {notices.map((notice) => (
                    <Link
                      to={`${process.env.PUBLIC_URL}/noticias/${notice.slug}`}
                      className="position-relative notice"
                    >
                      <img
                        // src={notice.thumbnail.archive_url}
                        src="https://institutobarueri.org.br/api/files/thumbnails/1698937842-emm-mario-bezerra.png"
                        alt="Dia do Professor"
                        className="w-100 h-100"
                      />
                      <p className="h5 h3-lg position-absolute title">
                        {notice.title}
                      </p>
                    </Link>
                  ))}
                </SliderSlick>
              </div>
            </div>
          </div>
        </Notices>
      )}
      <Institute id="sobre-nos">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 order-1 order-lg-0 px-4 px-lg-3 ">
              <img
                src={institute}
                alt="Nosso instituto"
                className="your-institute w-100"
              />
            </div>
            <div className="col-lg-6 order-0 order-lg-1  px-4 px-lg-3 text-lg-end">
              <h2 className="mb-4">
                Nosso <span>Instituto</span>
              </h2>
              <p className="mb-4">
                Somos o Instituto Barueri, uma associação do Terceiro Setor, sem
                fins lucrativos, de Direito Privado, com princípio voltado à
                Inclusão Social, qualificado como Organização Social do
                município de Barueri/SP.
              </p>
              <p>
                O Instituto Barueri se propõe a gerenciar escolas destinadas a
                alunos de 0 a 3 anos, em período integral, com a função de
                cuidar e educar em consonância com o conceito de Educação
                Inclusiva.
              </p>
            </div>
            <div className="col-12 pt-5 mt-5 order-2 px-4 px-lg-3 ">
              <h2>
                Nossas <span>estruturas</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <img
                src={structures}
                alt="Nossas estruturas"
                className="d-none d-sm-block mt-2 mt-5 pt-3 w-100"
              />
              <img
                src={structuresMob}
                alt="Nossas estruturas"
                className="d-sm-none mt-2 pt-3 w-100"
              />
            </div>
          </div>
        </div>
      </Institute>
      <Curriculum>
        <div className="container">
          <div className="row">
            <div className="col-12 px-4 px-lg-3">
              <div className="row">
                <div className="bg-join py-5 py-lg-0">
                  <div className="col-lg-5 h-100 d-flex flex-column justify-content-lg-center ms-4 ps-lg-5">
                    <h2 className="mb-4">
                      Faça parte da <br />
                      <span>nossa equipe</span>
                    </h2>
                    <h3 className="mb-4 mb-lg-5">
                      Envie seu currículo para nós
                    </h3>
                    <Link
                      to={`${process.env.PUBLIC_URL}/faca-parte-da-equipe`}
                      className="text-start d-flex align-items-center justify-content-center"
                    >
                      <MdEmail size={30} color="#fff" className="me-2" />
                      Enviar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Curriculum>
      <Values>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 px-4 px-lg-3 d-flex justify-content-around mb-4">
              <button
                type="button"
                onClick={() => handleBtnMisVisVal('mission')}
                className={`${btnSelected === 'mission' && 'active-mission'}`}
              >
                Missão
                <hr className="mx-auto w-50" />
              </button>
              <button
                type="button"
                onClick={() => handleBtnMisVisVal('vision')}
                className={`${btnSelected === 'vision' && 'active-vision'}`}
              >
                Visão
                <hr className="mx-auto w-50" />
              </button>
              <button
                type="button"
                onClick={() => handleBtnMisVisVal('values')}
                className={`${btnSelected === 'values' && 'active-values'}`}
              >
                Valores
                <hr className="mx-auto w-50" />
              </button>
            </div>
          </div>
          {btnSelected === 'mission' && (
            <div className="row justify-content-center">
              <div className="col-lg-5 px-4 ps-lg-3 pe-lg-0 mb-4 mb-lg-0">
                <img src={mission} alt="Mission" className="w-100 bg" />
              </div>
              <div className="col-lg-5 px-4 px-lg-3">
                <div className="d-flex bg-mission content">
                  <div className="d-flex flex-column ps-4 ps-lg-5 pb-4 pb-xl-5">
                    <h3 className="mt-auto">Missão</h3>
                    <div className="bar" />
                    <p className="mt-4">
                      É desenvolver projetos que promovam o desenvolvimento
                      humano pautado na área educacional de forma a minimizar os
                      problemas causados pelas diferenças sociais, com promoção
                      do bem-estar-social, físico, psíquico com profissionais
                      qualificados e com foco de excelência no atendimento.
                    </p>
                  </div>
                  <img src={missionArc} alt="Mission" />
                </div>
              </div>
            </div>
          )}
          {btnSelected === 'vision' && (
            <div className="row justify-content-center">
              <div className="col-lg-5 px-4 ps-lg-3 pe-lg-0 mb-4 mb-lg-0">
                <img src={vision} alt="vision" className="w-100 bg" />
              </div>
              <div className="col-lg-5 px-4 px-lg-3">
                <div className="d-flex bg-vision content">
                  <div className="d-flex flex-column ps-4 ps-lg-5 pb-4 pb-xl-5">
                    <h3 className="mt-auto">Visão</h3>
                    <div className="bar" />
                    <p className="mt-4">
                      O INSTITUTO BARUERI, zelará pela excelência e execução no
                      desenvolvimento dos projetos, bem como pelos valores
                      éticos seja qual for a etnia, orientação, gênero e classe
                      social.
                    </p>
                  </div>
                  <img src={visionValuesArc} alt="Arc" />
                </div>
              </div>
            </div>
          )}
          {btnSelected === 'values' && (
            <div className="row justify-content-center">
              <div className="col-lg-5 px-4 ps-lg-3 pe-lg-0 mb-4 mb-lg-0">
                <img src={values} alt="values" className="w-100 bg" />
              </div>
              <div className="col-lg-5 px-4 px-lg-3">
                <div className="d-flex bg-values content">
                  <div className="d-flex flex-column ps-4 ps-lg-5 pb-4 pb-xl-5">
                    <h3 className="mt-auto">Valores</h3>
                    <div className="bar" />
                    <p className="mt-4">
                      Trabalhando com cuidado e prazer; transparência em todas
                      as nossas atitudes; respeito a tudo e a todos; construção
                      continua e coletiva do conhecimento; clareza nas
                      expectativas e foco para a superação delas. Além de
                      pilares que consideramos fundamentais para nossa conduta
                      como:
                      <br />
                      <br /> • Ética: Agir com integridade, honestidade moral e
                      intelectual e com respeito à legislação vigente sobre
                      todos os aspectos;
                      <br />
                      <br /> • Qualidade: Alcançar resultados consistentes
                      gerando para as partes interessadas;
                      <br />
                      <br /> • Humanização: Trabalhar com respeito, equidade,
                      integralidade, dignidade de forma universal;
                      <br />
                      <br /> • Inovação: Buscar continuamente tecnologias e
                      métodos de trabalhos inovadores;
                      <br />
                      <br /> • Sustentabilidade: Agir de maneira socialmente
                      justa, economicamente viável, culturalmente aceita e
                      alinhando as ações com os objetivos de desenvolvimento da
                      instituição;
                      <br />
                      <br /> • Transparência: Fazer uso correto e responsável da
                      aplicação dos recursos e prestar conta deste ato aos
                      órgãos responsáveis e à sociedade.
                    </p>
                  </div>
                  <img src={visionValuesArc} alt="Arc" />
                </div>
              </div>
            </div>
          )}
          <div className="row justify-content-center">
            <div className="col-lg-10 px-4 px-lg-3 mt-170">
              <div className="row">
                <div className="col-lg-4 text-center mt-4 mt-lg-0">
                  <div className="rounded-circle bg-heart mx-auto">
                    <RiHeart3Fill color="#fff" />
                  </div>
                  <h2 className="mt-4 mt-lg-5 mb-4">Humanização</h2>
                  <h5>
                    Trabalhar com respeito, equidade, integralidade, dignidade
                    de forma universal
                  </h5>
                </div>
                <div className="col-lg-4 text-center mt-4 mt-lg-0">
                  <div className="rounded-circle bg-pc mx-auto">
                    <MdOutlineComputer color="#fff" />
                  </div>
                  <h2 className="mt-4 mt-lg-5 mb-4">Inovação</h2>
                  <h5>
                    Buscar continuamente tecnologias e métodos de trabalhos
                    inovadores
                  </h5>
                </div>
                <div className="col-lg-4 text-center mt-4 mt-lg-0">
                  <div className="rounded-circle bg-transparenci mx-auto">
                    <MdInterests color="#fff" />
                  </div>
                  <h2 className="mt-4 mt-lg-5 mb-4">Transparência</h2>
                  <h5 className="pb-5 pb-lg-0 mb-5 mb-lg-2">
                    Utilizar recursos de forma responsável e prestar contas
                    apropriadas aos órgãos responsáveis e à sociedade.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Values>
      <Secretary>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11 col-xxl-10">
              <div className="text-center justify-content-evenly align-items-center bg-secretary">
                <img src={secretary} alt="Secretaria de Educação" />
                <a
                  href="https://www.educbarueri.sp.gov.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Secretaria da educação
                </a>
                <a
                  href="https://portal.barueri.sp.gov.br/diario"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jornal oficial de Barueri
                </a>
              </div>
            </div>
          </div>
        </div>
      </Secretary>
      <Loading active={loading} />
    </Container>
  );
};

export default Home;
